import {
  ClockConfig,
  HeaderWorldClock,
  HomePageStarredEntities,
  HomePageToolkit,
  HomePageTopVisited,
  HomePageRecentlyVisited,
} from '@backstage/plugin-home';
import { Content, Header, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import React from 'react';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { SentryIcon, GithubIcon, GoogleCloudIcon, JiraIcon, CloudflareIcon, ConfluenceIcon, AWSIcon, PagerDutyIcon, GrafanaIcon, SlackIcon } from '../../assets/icons';
import { Grid } from '@material-ui/core';

const HomePage = () => {
  const clockConfigs: ClockConfig[] = [
    {
      label: 'EST',
      timeZone: 'EST',
    },
    {
      label: 'UTC',
      timeZone: 'UTC',
    }
  ];

  const timeFormat: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Header title="Home">
          <HeaderWorldClock
            clockConfigs={clockConfigs}
            customTimeFormat={timeFormat}
          />
        </Header>
        <Content>
          <Grid container justifyContent="center" spacing={2}>
            <Grid container item xs={12} alignItems="center" direction="row">
              <HomePageSearchBar placeholder="Search" />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={4}>
                <HomePageRecentlyVisited />
              </Grid>
              <Grid item xs={12} md={4}>
                <HomePageTopVisited />
              </Grid>
              <Grid item xs={12} md={4}>
                <HomePageStarredEntities
                />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} alignItems="center" direction="row">
                <HomePageToolkit
                  tools={[
                    {
                      url: 'https://demarque.sentry.io/',
                      label: 'Sentry',
                      icon: <SentryIcon />,
                    },
                    {
                      url: 'https://github.com/demarque',
                      label: 'GitHub',
                      icon: <GithubIcon />,
                    },
                    {
                      url: 'https://console.cloud.google.com/welcome?project=demarque&supportedpurview=project',
                      label: 'Cloud Console',
                      icon: <GoogleCloudIcon />,
                    },
                    {
                      url: 'https://627104196968.signin.aws.amazon.com/console',
                      label: 'AWS',
                      icon: <AWSIcon />,
                    },
                    {
                      url: 'https://jira.demarque.com/',
                      label: 'Jira',
                      icon: <JiraIcon />,
                    },
                    {
                      url: 'https://confluence.demarque.com/',
                      label: 'Confluence',
                      icon: <ConfluenceIcon />,
                    },
                    {
                      url: 'https://dash.cloudflare.com/59020c5b1b89f1a30843ac28e45707bf',
                      label: 'Cloudflare',
                      icon: <CloudflareIcon />,
                    },
                    {
                      url: 'https://demarque.pagerduty.com',
                      label: 'PagerDuty',
                      icon: <PagerDutyIcon />,
                    },
                    {
                      url: 'https://metrics.prod-northamerica.demarque.com',
                      label: 'Grafana',
                      icon: <GrafanaIcon />,
                    },
                    {
                      url: 'https://demarque.slack.com',
                      label: 'Slack',
                      icon: <SlackIcon />,
                    }
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};

export const homePage = <HomePage />;
