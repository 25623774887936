import { BackstageOverrides } from '@backstage/core-components';
import {
    BackstagePaletteOptions,
    createTheme,
    genPageTheme,
    darkTheme,
    lightTheme,
    BackstageTheme,
    SimpleThemeOptions,
    shapes,
} from '@backstage/theme';

const customDarkPalleteColors: BackstagePaletteOptions = {
    ...darkTheme.palette,
    primary: {
        main: '#ffffff',
    }
};

const customLightPalleteColors: BackstagePaletteOptions = {
    ...lightTheme.palette,
    primary: {
        main: '#000000',
    }
};

const customDarkThemeOptions: SimpleThemeOptions = {
    palette: customDarkPalleteColors,
    defaultPageTheme: 'home',
    pageTheme: {
        home: genPageTheme({ colors: ['#000000', '#DB0000', '#a80000', '#000000'], shape: shapes.wave }),
    },
};

const customLightThemeOptions: SimpleThemeOptions = {
    palette: customLightPalleteColors,
    defaultPageTheme: 'home',
    pageTheme: {
        home: genPageTheme({ colors: ['#000000', '#DB0000', '#a80000', '#000000'], shape: shapes.wave }),
    },
};

const myDarkCustomTheme: BackstageTheme = createTheme(customDarkThemeOptions);
const myLightCustomTheme: BackstageTheme = createTheme(customLightThemeOptions);

const customThemeOverrides = (): BackstageOverrides => {
    return {
        BackstageSidebar: {
            drawer: {
                backgroundColor: '#171717',
            }
        }
    };
};

export const customDarkTheme: BackstageTheme = {
    ...myDarkCustomTheme,
    overrides: {
        ...myDarkCustomTheme.overrides,
        ...customThemeOverrides(),
    },
};

export const customLightTheme: BackstageTheme = {
    ...myLightCustomTheme,
    overrides: {
        ...myLightCustomTheme.overrides,
        ...customThemeOverrides(),
    },
};
