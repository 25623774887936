import {
  TechRadarLoaderResponse,
  TechRadarApi,
} from '@backstage/plugin-tech-radar';

import { ConfigApi } from '@backstage/core-plugin-api';

export class TechRadarClient implements TechRadarApi {
  config: ConfigApi;
  constructor(config: ConfigApi) {
    this.config = config;
  }
  async load(_id: string | undefined): Promise<TechRadarLoaderResponse> {
    return {
      entries: [],
      rings: [],
      quadrants: [],
    };
  }
}
