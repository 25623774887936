import {
  googleAuthApiRef,
} from '@backstage/core-plugin-api';

export const providers = [
  {
    id: 'google-auth-provider',
    title: 'Google',
    message: 'Sign In using Google',
    apiRef: googleAuthApiRef,
  }
];
